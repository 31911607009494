body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Webkit browsers */
.search-list.scrollable {
  overflow-y: auto;
  max-height: 70px;
  border-bottom: 1px solid #e0e0e0;
}

.scrollable::-webkit-scrollbar {
  width: 12px;
}

.scrollable::-webkit-scrollbar-track {
  background: #25252900;
}

.scrollable::-webkit-scrollbar-thumb {
  background: rgb(16, 138, 0, 0.5);
}

/* Firefox */
.scrollable {
  scrollbar-width: thin;
  scrollbar-color: rgb(16, 138, 0, 0.5) #25252900;
}

.job-card {
  width: 90%;
  margin: 0 auto;
}

.job-card:hover {
  background-color: #efefef;
}

/* ------------------------------------------- */

.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: 1px solid rgb(16, 138, 0, 0.5);
  background-color: #fff;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(16, 138, 0, 0.5);
  color: #fff;
}

.pagination button:disabled {
  background-color: #eee;
  cursor: not-allowed;
}

/* ------------------------------------------- */
.border-right {
  border-right: 2px solid #333;
}
/* ------------------------------------------- */
.tooltip-content {
  visibility: visible;
  background-color: rgb(138, 138, 138);
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgb(138, 138, 138) transparent;
}

/* ---------------------------- */
.react-datepicker__input-container {
  margin-left: 30px;
}

.react-datepicker__input-container input {
  border: 2px solid green;
  border-radius: 10px;
  padding: 10px;
}

.react-datepicker-popper {
  left: 20px !important;
}

.react-datepicker__month-container {
  border: 2px solid green;
  border-radius: 4px;
}

.react-datepicker__day--in-selecting-range {
  background-color: rgb(166, 203, 166) !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: green !important;
}

.react-datepicker__day--today {
  background-color: rgb(50, 208, 50) !important;
  color: #fff !important;
  border-radius: 5px;
}

/* ============================ */
.job-description {
  word-wrap: break-word;
  width: 100%;
  height: 5rem;
  line-height: 1.2rem;
  overflow-y: auto;
  text-overflow: ellipsis;
}

.search-elem-item {
  border-radius: 15px;
  border: 1px solid rgb(220 220 220);
  background: rgb(226 225 225);
  padding: 3px 7px;
  color: rgb(141 139 139);
  font-size: 11px;
  font-weight: 500;
  margin: 5px;
  display: inline-block;
}
/* ============================ */
.stats-divider {
  text-align: center;
  background: green !important;
  color: white;
  font-weight: 700;
}

.header-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 40px 0;
}

.header-menu-btn {
  border: 2px solid rgb(16, 138, 0.2) !important;
  border-radius: 20px !important;
  padding: 10px 15px !important;
  font-weight: 600 !important;
  display: block !important;
  height: 48px !important;
  background: transparent !important;
  color: #000 !important;
}

.header-menu-btn.active {
  background: rgb(16, 138, 0.2) !important;
  color: #fff !important;
}

/* ================================ */
.key-words-handler-block {
  height: 150px;
  overflow-y: hidden;
  border-radius: 5px;
  border-bottom: 1px solid #b0b0b0;
}

.key-words-handler-open-btn {
  position: absolute;
  bottom: -7%;
  left: 50%;
  padding: 0 0 5px 0;
  color: green;
  background-color: rgb(223 254 219);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-shadow: inset -1px -1px 4px 0px #0000004f;
  font-size: 30px;
}
